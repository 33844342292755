import { http } from '@/http/axios.js'
import axios from 'axios'
// 列表
export function getListAPI(params) {
  return http({
    url: '/company/chargingLock/list',
    method: 'get',
    params
  })
}
// 详情
export function getDetailAPI(id) {
  return http({
    url: '/company/chargingLock/detail',
    method: 'get',
    params: { id }
  })
}
// add
export function addAPI(data) {
  return http({
    url: '/company/chargingLock/add',
    method: 'post',
    data
  })
}
// edit
export function editAPI(data) {
  return http({
    url: '/company/chargingLock/edit',
    method: 'post',
    data
  })
}
// del
export function delAPI(id) {
  return http({
    url: '/company/chargingLock/delete',
    method: 'post',
    data: { id }
  })
}
export function downloadFile(pdfUrl, name) {
  axios({
    url: pdfUrl,
    method: 'GET',
    responseType: 'blob' // 必须指定为blob类型才能下载
  }).then(res => {
    const url = window.URL.createObjectURL(new Blob([res.data]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', `${name}.png`)
    document.body.appendChild(link)
    link.click()
  })
}

// 地锁配置详情
export function getLockConfigDetailAPI(id) {
  return http({
    url: '/company/chargingLock/getConfiguration',
    method: 'get',
    params: { id }
  })
}
// 地锁配置
export function updateLockConfigDetailAPI(data) {
  return http({
    url: '/company/chargingLock/setConfiguration',
    method: 'post',
    data
  })
}
// up
export function handleLockUpAPI(id) {
  return http({
    url: '/company/chargingLock/upLock',
    method: 'post',
    data: { id }
  })
}
export function handleLockDownAPI(id) {
  return http({
    url: '/company/chargingLock/downLock',
    method: 'post',
    data: { id }
  })
}
