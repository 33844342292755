<template>
  <div class="wrapper">
    <!-- 地锁-->
    <admin-title :title="$route.meta.name"></admin-title>
    <div class="header-search">
      <el-form ref="form" inline :model="searchData" label-width="">
        <el-form-item label="充电站">
          <SelectStation v-model="searchData.station_id" width="200px"></SelectStation>
        </el-form-item>
        <el-form-item label="地锁编号">
          <el-input style="width: 200px" v-model="searchData.equipment_id" size="small" placeholder="请输入" clearable></el-input>
        </el-form-item>
        <el-form-item label="控制器号">
          <el-input style="width: 200px" v-model="searchData.equipment_id_hex" size="small" placeholder="请输入" clearable></el-input>
        </el-form-item>
        <el-form-item label="状态">
          <el-select style="width: 200px" v-model="searchData.device_state" clearable placeholder="请选择" size="small">
            <el-option label="全部" :value="0"> </el-option>
            <el-option label="在线" :value="1"> </el-option>
            <el-option label="离线" :value="2"> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label=" ">
          <el-button type="primary" size="small" icon="el-icon-search" @click="getList()">搜索</el-button>
          <el-button type="info" size="small" icon="el-icon-refresh" @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-divider></el-divider>
    <div class="header-btn">
      <el-button
        v-checkbtn="isShowBtn(AUTH_BTN.lock_list_add)"
        @click="$router.push({ name: 'ChargeLockAdd' })"
        type="primary"
        icon="el-icon-plus"
        size="small"
        >新增</el-button
      >
    </div>
    <tp-table
      :isNeedSerialNumber="false"
      :tableData="list"
      :columns="columns"
      :totalNum="total"
      :current-page.sync="currentPage"
      :pageSize.sync="pageSize"
    />
    <!-- 二维码 -->
    <el-dialog :title="`编号：${lock_id}`" :visible.sync="show" width="width">
      <el-divider content-position="center">地锁二维码</el-divider>
      <!-- <Qrcode ref="code"></Qrcode> -->
      <div class="code">
        <img :src="qr_code" alt="" />
      </div>
      <div class="download-btn">
        <el-button type="primary" size="small" @click="downloadCode">下载</el-button>
      </div>
    </el-dialog>
    <!-- 地锁配置 -->
    <el-dialog title="地锁下发配置" :visible.sync="lockDialogVisible" width="width">
      <el-form ref="formLock" inline :model="lockData" label-width="130px">
        <!-- <el-form-item label="设备类型">
          <el-input v-model="lockData.equipment_type" placeholder="请输入" size="small"></el-input>
        </el-form-item>
        <el-form-item label="设备SN号">
          <el-input v-model="lockData.equipment_id" placeholder="请输入" size="small"></el-input>
        </el-form-item> -->
        <el-form-item style="width: 40%" label="寻限位遇阻档位">
          <el-input-number step-strictly :step="1" :min="0" v-model="lockData.limit_block" placeholder="请输入" size="small"></el-input-number>
        </el-form-item>
        <el-form-item style="width: 40%" label="开遇阻档位">
          <el-input-number step-strictly :step="1" :min="0" v-model="lockData.open_block" placeholder="请输入" size="small"></el-input-number>
        </el-form-item>
        <el-form-item style="width: 40%" label="关遇阻档位">
          <el-input-number step-strictly :step="1" :min="0" v-model="lockData.close_block" placeholder="请输入" size="small"></el-input-number>
        </el-form-item>
        <el-form-item style="width: 40%" label="最小功率">
          <el-input-number step-strictly :step="1" :min="0" v-model="lockData.min_power" placeholder="请输入" size="small"></el-input-number>
        </el-form-item>
        <el-form-item style="width: 40%" label="开最大功率">
          <el-input-number step-strictly :step="1" :min="0" v-model="lockData.open_max_power" placeholder="请输入" size="small"></el-input-number>
        </el-form-item>
        <el-form-item style="width: 40%" label="关最大功率">
          <el-input-number step-strictly :step="1" :min="0" v-model="lockData.close_max_power" placeholder="请输入" size="small"></el-input-number>
        </el-form-item>
        <el-form-item style="width: 40%" label="寻限位最大功率">
          <el-input-number
            step-strictly
            :step="1"
            :min="0"
            v-model="lockData.limit_block_max_power"
            placeholder="请输入"
            size="small"
          ></el-input-number>
        </el-form-item>
        <el-form-item style="width: 40%" label="有车自动升板时间">
          <el-input-number
            step-strictly
            :step="1"
            :min="0"
            v-model="lockData.device_running_stability"
            placeholder="请输入"
            size="small"
          ></el-input-number>
          <span class="tip">秒</span>
        </el-form-item>
        <el-form-item label="无车自动升板时间">
          <el-input-number step-strictly :step="1" :min="0" v-model="lockData.tight_rebound" placeholder="请输入" size="small"></el-input-number>
          <span class="tip">{{ ((Number(lockData.tight_rebound) * 100 * 0.02) / 60).toFixed(2) }} 分钟</span>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="lockDialogVisible = false" size="small">取 消</el-button>
        <el-button type="primary" @click="submitLockConfig" size="small">确 定</el-button>
      </div>
    </el-dialog>
    <div style="height: 50px"></div>
  </div>
</template>

<script>
import { authBtnMixin } from '@/mixins/authBtnMixin'
import { getListAPI, delAPI, downloadFile, getLockConfigDetailAPI, updateLockConfigDetailAPI, handleLockUpAPI, handleLockDownAPI } from './api'
import SelectStation from '@/views/components/select-station.vue'

const columns = [
  {
    label: 'ID',
    prop: 'id',
    minWidth: '100',
    customRender(h, row) {
      return <p>{row['id']}</p>
    }
  },
  {
    label: '充电站',
    prop: 'charging_station',
    minWidth: '120',
    customRender(h, row) {
      return <p>{row['charging_station']?.['name']}</p>
    }
  },

  {
    label: '名称',
    prop: 'name',
    minWidth: '120',
    customRender(h, row) {
      return <p>{row['name']}</p>
    }
  },
  {
    label: '地锁编号',
    prop: 'equipment_id',
    minWidth: '120',
    customRender(h, row) {
      return <p>{row['equipment_id']}</p>
    }
  },
  {
    label: '控制器号',
    prop: 'equipment_id_hex',
    minWidth: '120',
    customRender(h, row) {
      return <p>{row['equipment_id_hex']}</p>
    }
  },

  {
    label: '状态',
    prop: 'status',
    minWidth: '100',
    customRender(h, row) {
      return (
        <div>
          <el-tag v-show={row['status'] == 0} type="primary" size="small">
            {row['status_dsc']}
          </el-tag>
          <el-tag v-show={row['status'] == 1} type="warning" size="small">
            {row['status_dsc']}
          </el-tag>
          <el-tag v-show={row['status'] == 2} type="danger" size="small">
            {row['status_dsc']}
          </el-tag>
        </div>
      )
    }
  },

  {
    label: '二维码',
    prop: 'equipment_id',
    minWidth: '120',
    customRender(h, row) {
      return (
        <p onClick={() => this.showCode(row)}>
          <el-image v-show={row['qr_code']} style="width:60px;height:60px;display:block;cursor: pointer;margin:5px 0" src={row['qr_code']}></el-image>
          <span style="margin-left:1em" v-show={!row['qr_code']}>
            -
          </span>
        </p>
      )
    }
  },
  {
    label: '创建时间',
    prop: 'create_at',
    minWidth: '160',
    customRender(h, row) {
      return <p>{row['created_at']}</p>
    }
  },
  {
    label: '操作',
    width: '200',
    fixed: 'right',
    customRender(h, row) {
      return (
        <div>
          <el-link
            disabled={!this.isShowBtn(this.AUTH_BTN.lock_list_config)}
            underline={false}
            type="primary"
            onClick={() => this.handleLockConfig(row)}
          >
            配置
          </el-link>
          <el-link
            disabled={!this.isShowBtn(this.AUTH_BTN.lock_list_edit)}
            style="margin:0 10px"
            underline={false}
            type="primary"
            onClick={() => this.$router.push({ name: 'ChargeLockEdit', params: { id: row['id'] } })}
          >
            编辑
          </el-link>
          <el-link style="margin-right:10px" underline={false} type="primary" onClick={() => this.handleUp(row)}>
            升锁
          </el-link>
          <el-link underline={false} type="primary" onClick={() => this.handleDown(row)}>
            降锁
          </el-link>
        </div>
      )
    }
  }
]
export default {
  name: 'List',
  mixins: [authBtnMixin],
  components: { SelectStation },
  data() {
    return {
      lockDialogVisible: false,
      show: false,
      columns,
      currentPage: 1,
      pageSize: 10,
      total: 0,
      list: [],
      searchData: {
        station_id: '',
        equipment_id: '',
        equipment_id_hex: '',
        device_state: 0
      },
      lock_id: '',
      qr_code: '',
      lockData: {
        id: '',
        equipment_type: '',
        equipment_id: '',
        limit_block: '',
        open_block: '',
        close_block: '',
        min_power: '',
        open_max_power: '',
        close_max_power: '',
        limit_block_max_power: '',
        device_running_stability: '',
        tight_rebound: ''
      }
    }
  },
  provide() {
    return {
      context: this
    }
  },
  mounted() {},
  activated() {
    this.getList()
  },
  watch: {
    currentPage() {
      this.getList()
    },
    pageSize() {
      this.getList()
    }
  },
  methods: {
    async getList() {
      const { equipment_id, station_id, equipment_id_hex, device_state } = this.searchData
      let params = { page: this.currentPage, page_size: this.pageSize, equipment_id, station_id, equipment_id_hex, device_state }
      const res = await getListAPI(params)
      this.list = res.data || []
      this.total = res.total || 0
    },
    handleRemove({ id }) {
      this.$confirm('此操作将永久删除该地锁, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          delAPI(id).then(() => {
            this.$message({
              type: 'success',
              message: '删除成功!'
            })
            this.getList()
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    reset() {
      Object.assign(this._data.searchData, this.$options.data().searchData)
      this.getList()
    },
    showCode({ qr_code, equipment_id }) {
      if (!qr_code) return
      this.lock_id = equipment_id
      this.qr_code = qr_code
      this.show = true
      // this.$nextTick(function () {
      //   this.$refs.code.QRlink = qr_code
      //   this.$refs.code.getQRcode(512, 512)
      // })
    },
    downloadCode() {
      downloadFile(this.qr_code, this.lock_id)
    },
    async handleLockConfig({ id }) {
      this.lockData.id = id
      const res = await getLockConfigDetailAPI(id)
      Object.assign(this.lockData, res)
      this.lockDialogVisible = true
    },
    submitLockConfig() {
      updateLockConfigDetailAPI(this.lockData).then(() => {
        this.$message.success('配置成功')
        this.lockDialogVisible = false
      })
    },
    // 上升
    handleUp({ id }) {
      handleLockUpAPI(id).then(() => {
        this.getList()
        this.$message.success('升锁成功')
      })
    },
    // 下降
    handleDown({ id }) {
      handleLockDownAPI(id).then(() => {
        this.getList()
        this.$message.success('降锁成功')
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper /deep/ {
  width: 100%;
  .header-search {
    .el-form {
      .el-form-item {
        margin-bottom: 10px;
      }
    }
  }
  .el-divider--horizontal {
    margin: 0 0 10px;
  }
  .header-btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  .tp-table {
    .el-table__fixed-right {
      height: 100% !important;
    }
  }
  .el-dialog__body {
    padding-top: 0;
  }
  .download-btn {
    text-align: center;
  }
  .code {
    display: flex;
    justify-content: center;
    padding: 20px 0;
  }
  .tip {
    margin-left: 10px;
  }
}
</style>
